// components/DonationBreakdownModal.tsx

import { useState, useEffect } from "react";
import { 
  FaInfoCircle, FaStethoscope, FaUtensils, FaSyringe, FaBath, FaVial, FaBug, FaMedkit, 
  FaHome, FaCarSide, FaClipboardCheck, FaNotesMedical, FaPills, FaHeartbeat, FaMicrochip, 
  FaBandAid, FaSoap, FaClinicMedical 
} from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';

export default function DonationBreakdownModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iconSize, setIconSize] = useState(16); // Default icon size for hydration-safe SSR

  // Dynamically adjust icon size based on screen size
  const isMdOrLarger = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    setIconSize(isMdOrLarger ? 24 : 16);
  }, [isMdOrLarger]);

  const donationItems = [
    { icon: <FaStethoscope size={iconSize} />, name: "Spaying/Neutering Surgery", cost: 300 },
    { icon: <FaUtensils size={iconSize} />, name: "Food and Nutritional Supplies", cost: 150 },
    { icon: <FaSyringe size={iconSize} />, name: "Vaccination Costs", cost: 50 },
    { icon: <FaBath size={iconSize} />, name: "Grooming and Bathing", cost: 110 },
    { icon: <FaVial size={iconSize} />, name: "Blood Tests", cost: 60 },
    { icon: <FaBug size={iconSize} />, name: "Parasite Treatment (Fleas/Ticks)", cost: 60 },
    { icon: <FaMedkit size={iconSize} />, name: "Emergency Medical Support", cost: 900 },
    { icon: <FaHome size={iconSize} />, name: "Shelter and Housing", cost: 50 },
    { icon: <FaCarSide size={iconSize} />, name: "Transportation Costs", cost: 100 },
    { icon: <FaNotesMedical size={iconSize} />, name: "FIV/FeLV Testing", cost: 120 },
    { icon: <FaPills size={iconSize} />, name: "Deworming Medication", cost: 100 },
    { icon: <FaHeartbeat size={iconSize} />, name: "Health Checkups", cost: 100 },
    { icon: <FaMicrochip size={iconSize} />, name: "Microchip Implantation", cost: 30 },
    { icon: <FaBandAid size={iconSize} />, name: "Wound Care and Medication", cost: 1800 },
    { icon: <FaSoap size={iconSize} />, name: "Sanitary Supplies", cost: 190 },
    { icon: <FaClinicMedical size={iconSize} />, name: "Veterinarian Consultation Fees", cost: 28 },
    { icon: <FaClipboardCheck size={iconSize} />, name: "Adoption Process Costs", cost: 360 },
  ];

  return (
    <div className="flex flex-col items-center w-full py-0 px-8 sm:px-20 gap-4 md:gap-8 bg-lightIvory text-darkBrown">
      <div className="flex items-center">
        <h2 className="text-xl md:text-3xl font-semibold">Donation Usage Breakdown</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="ml-2 text-customBlue focus:outline-none"
          aria-label="See donation amounts"
        >
          <FaInfoCircle size={24} />
        </button>
      </div>
      <p className="text-sm md:text-lg max-w-2xl text-darkBrown/80 text-center">
        We value financial transparency.
        <span className="block md:inline"> See how your donations are used.</span>
      </p>

      <div className="grid gap-2 md:gap-8 sm:grid-cols-1 lg:grid-cols-2 w-full max-w-5xl">
        <ul className="space-y-4">
          {donationItems.slice(0, Math.ceil(donationItems.length / 2)).map((item, index) => (
            <li key={index} className="flex items-center">
              <span className="mr-3">{item.icon}</span>
              <p className="text-sm md:text-lg text-darkBrown/80">{item.name}</p>
            </li>
          ))}
        </ul>
        <ul className="space-y-4 mt-2 md:mt-0">
          {donationItems.slice(Math.ceil(donationItems.length / 2)).map((item, index) => (
            <li key={index} className="flex items-center">
              <span className="mr-3">{item.icon}</span>
              <p className="text-sm md:text-lg text-darkBrown/80">{item.name}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl p-2"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close modal"
            >
              &times;
            </button>
            <h3 className="text-2xl font-semibold mb-4">Average Costs</h3>
            <h3 className="text-xs mb-4 text-left">
              *The average price is determined based on rates from a central Seoul veterinary.
            </h3>
            <ul className="space-y-2">
              {donationItems.map((item, index) => (
                <li key={index} className="flex justify-between">
                  <span>{item.name}</span>
                  <span>${item.cost}+</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
