"use client";

import Image from "next/image";
import { useEffect, useState } from "react";
import Link from "next/link";
import DonationBreakdownModal from "./components/modals/DonationBreakdownModal";

// Define a type based on the Fundraising model structure
type Fundraising = {
  id: number;
  name: string;
  title: string;
  description: string;
  imageUrl: string;
  targetAmount: number;
  currentAmount: number;
  status: string;
  endDate?: string;
};

type DonationData = {
  totalFundedAmount: number;
  donations: {
    donor: string;
    totalAmount: number;
  }[];
};

type Statistics = {
  totalRescued: number;
  totalFundsRaised: number;
  totalSupporters: number;
  totalCatsUnderCare: number;
};

export default function Home() {
  const [activeProjects, setActiveProjects] = useState<Fundraising[]>([]);
  const [completedProjects, setCompletedProjects] = useState<Fundraising[]>([]);
  const [donationData, setDonationData] = useState<Record<number, DonationData>>({});
  const [statistics, setStatistics] = useState<Statistics | null>(null);

  useEffect(() => {
    const fetchFundraisingProjects = async () => {
      try {
        const activeRes = await fetch("/api/fundraising/get?status=ACTIVE");
        const activeData: Fundraising[] = await activeRes.json();
        setActiveProjects(activeData.slice(0, 3)); // 상태 업데이트
  
        // 상태 업데이트 이후 donation 데이터 가져오기
        for (const project of activeData.slice(0, 3)) {
          await fetchDonationData(project.id);
        }
  
        const completedRes = await fetch("/api/fundraising/get?status=COMPLETED");
        const completedData: Fundraising[] = await completedRes.json();
        setCompletedProjects(completedData.slice(0, 3)); // 상태 업데이트
      } catch (error) {
        console.error("Error fetching fundraising projects:", error);
      }
    };

    const fetchDonationData = async (id: number) => {
      try {
        const res = await fetch(`/api/donation/${id}`);
        const data: DonationData = await res.json();
        setDonationData((prevData) => ({
          ...prevData,
          [id]: data,
        }));
      } catch (error) {
        console.error(`Error fetching donation data for project ${id}:`, error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const res = await fetch("/api/statistics/get");
        const data: Statistics = await res.json();
        setStatistics(data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchFundraisingProjects();
    fetchStatistics();
  }, []);


  return (
    <div className="min-h-screen flex flex-col items-center bg-ivory text-darkBrown font-[family-name:var(--font-geist-sans)]">
      {/* Banner Section */}
      <section className="w-full h-[40vh] md:h-[70vh] relative flex flex-col justify-center items-center text-white text-center">
        <Image
          className="absolute inset-0 w-full h-full object-cover opacity-100"
          src="/banners/banner.png"
          alt="YongsanCats Banner"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority
        />
        <div className="relative z-10 mb-auto mt-4 md:mt-20 px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-2 md:mb-4 drop-shadow-lg">
            Every cat has a story.
          </h1>
          <p className="text-lg md:text-xl drop-shadow-md">
            Give a Voice to Stray Cats.
            <span className="block md:inline"> Help Us Find Forever Homes.</span>
          </p>

        </div>
        <div className="relative z-10 mt-auto mb-10 md:mb-20">
          <Link
            href="/d"
            className="inline-block px-8 py-4 text-md md:text-lg font-semibold bg-customGreen text-lightIvory rounded-full hover:bg-customGreenHover transition-colors shadow-lg"
          >
            Donate Now
          </Link>
        </div>
      </section>

      {/* Active Projects Section */}
      <main className="flex flex-col items-center w-full gap-4 text-center bg-beige">
        <h2 className="text-xl md:text-3xl mt-12 font-semibold text-darkBrown">Stray Cats in Need</h2>
        <section className="grid gap-8 p-4 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-5xl">
          {activeProjects.map((project) => {
            const donationInfo = donationData[project.id];
            const totalFunded = donationInfo?.totalFundedAmount || 0;
            const progressPercentage = Math.min((totalFunded / project.targetAmount) * 100, 100);

            return (
              <div
                key={project.id}
                className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow"
              >
                <Image
                  src={project.imageUrl}
                  alt={`Rescue Project ${project.name}`}
                  width={400}
                  height={200}
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl md:text-2xl font-semibold mb-2 text-darkBrown">{project.title}</h3>
                <p className="text-sm text-darkBrown/80 mb-4 line-clamp-3 overflow-hidden text-ellipsis">
                  {project.description}
                </p>
                <div className="w-full bg-gray-300 rounded-full h-2.5 mb-2">
                  <div
                    className="bg-green-500 h-2.5 rounded-full transition-width duration-500"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <p className="text-sm text-darkBrown mb-4">
                  ${totalFunded} raised of ${project.targetAmount}
                </p>
                <Link
                  href={`/d/${project.id}`}
                  className="inline-block px-4 py-2 text-sm bg-customGreen text-white rounded-full hover:bg-customGreenHover transition-colors"
                >
                  Donate Now
                </Link>
              </div>
            );
          })}
        </section>

        {/* Impact and Transparency Section */}
        <section className="flex flex-col items-center w-full py-10 px-8 sm:px-20 gap-8 bg-lightIvory text-darkBrown">
          <DonationBreakdownModal />
          <p className="text-sm md:text-lg max-w-2xl mt-2 text-darkBrown/80 text-center">
            YOUR contributions make a REAL DIFFERENCE.
          </p>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-5xl">
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
              <h3 className="text-2xl md:text-4xl font-bold text-customRed">{statistics?.totalCatsUnderCare || 0}+</h3>
              <p className="text-sm md:text-lg text-darkBrown/80">Total Cats Under Care</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
              <h3 className="text-2xl md:text-4xl font-bold text-customRed">{statistics?.totalRescued || 0}+</h3>
              <p className="text-sm md:text-lg text-darkBrown/80">Cats Rescued</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
            <h3 className="text-2xl md:text-4xl font-bold text-customRed">
            {statistics?.totalFundsRaised !== undefined
              ? `$${statistics.totalFundsRaised.toLocaleString()}`
              : "$0"}
          </h3>

              <p className="text-sm md:text-lg text-darkBrown/80">Total Donations Received</p>
            </div>
          </div>
        </section>

        {/* Completed Projects Section */}
        <section className="flex flex-col items-center w-full py-8 px-8 sm:px-20 gap-8 bg-beige text-darkBrown">
          <p className="text-sm md:text-lg max-w-2xl text-darkBrown/80 text-center">
            Our stray cats at their foster homes or forever homes.
          </p>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-5xl">
            {completedProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center hover:shadow-xl transition-shadow"
              >
                <Image
                  src={project.imageUrl}
                  alt={`Rescue Project ${project.name}`}
                  width={400}
                  height={200}
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-2xl font-semibold mb-2 text-darkBrown">{project.name}</h3>
                <p
                  className="text-sm text-darkBrown/80 mb-4 line-clamp-3 overflow-hidden text-ellipsis"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {project.description}
                </p>
                <Link
                  href={`/d/${project.id}`}
                  className="text-warmPink hover:underline"
                >
                  Learn More →
                </Link>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}
